<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                    <h2>Jurnal Umum</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Regional</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <v-select id="Region" class="pb-3" :options="RegionData" v-model="Region" @input="gridChange()"/>
                        <label id="errorRegion" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Year</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <date-picker
                            v-model="Year"
                            format="YYYY"
                            type="year"
                            placeholder="Select year"
                            value-type="YYYY"
                            style="width:100%"
                            @change = "gridChange"
                        ></date-picker>
                        <label id="errorYear" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Month</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <date-picker
                            v-model="Month"
                            format="MMMM"
                            type="month"
                            placeholder="Select month"
                            value-type="M"
                            style="width:100%"
                            @change = "gridChange"
                        ></date-picker>
                        <label id="errorMonth" class="form-error pb-2" style="display: none; color: red;"></label>
                    </div>
                </CRow>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>&nbsp;
                    <button id="add" class="btn btn-primary"                   
                    @click="addPurchasingPaymentClick()">
                        <i class="fa fa-plus"></i> Purchasing Payment
                    </button>
                </div>

                <general-journal-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" :pdfClick="pdfClick"/>
                <general-journal-status-form ref="GeneralJournalStatusForm" :reload="reload" />
                <general-journal-pdf ref="pdf" />
                <purchasing-payment-create-form ref="PurchasingPaymentCreateForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import generalJournalGrid from '../GeneralJournal/Grid/GeneralJournalGrid.vue';
import generalJournalServices from './Script/GeneralJournalService.js';
import generalJournalStatusForm from './Component/GeneralJournalStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import generalJournalPDF from '../../../assets/template/GeneralJournalPdfTemplate.vue';
import { sweet_alert } from '../../../infrastructure/constant/variable';
import purchasingPaymentCreateForm from '../GeneralJournal/Component/PurchasingPaymentCreateForm.vue';

export default {
    name: 'GeneralJournal',
    components: {
        'date-picker' : datePicker,
        'general-journal-grid': generalJournalGrid,
        'general-journal-status-form': generalJournalStatusForm,
        'general-journal-pdf': generalJournalPDF,
        'purchasing-payment-create-form': purchasingPaymentCreateForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Jurnal Umum');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        //Setup Cookies
        var region = window.$cookies.get('GeneralJournal-Region');
        this.RegionData = await generalJournalServices.getRegionQuery();
        this.Region = region == "null" ? null : this.RegionData.find(c => c.value == region);

        this.Month = window.$cookies.get('GeneralJournal-Month') == "null" ? null : window.$cookies.get('GeneralJournal-Month');
        this.Year = window.$cookies.get('GeneralJournal-Year') == "null" ? null : window.$cookies.get('GeneralJournal-Year');
        this.Status = window.$cookies.get('GeneralJournal-Status') == "null" ? null : window.$cookies.get('GeneralJournal-Status');
        
        this.statusFilter();
        this.gridChange();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
            Year: null,
            Month: null,
            RegionData: [],
            Region: null,
            Status: null,
        }
    },
    methods: {
        gridChange(){
            var region = this.Region == null ? null : this.Region.value;
            this.$cookies.set('GeneralJournal-Year', this.Year, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('GeneralJournal-Month', this.Month, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('GeneralJournal-Region', region, this.$cookies.get('TokenExpireTime'));
            this.$cookies.set('GeneralJournal-Status', this.Status, this.$cookies.get('TokenExpireTime'));
            this.$refs.grid.changeGrid(this.Status, region, this.Month, this.Year);
        },
        addClick(){
            this.$router.push({ name: 'General Journal Form', params: {  formtype:'Add', id:' ', view:'new' } })
        },
        editClick(id, view){
            const routeData = this.$router.resolve({name: 'General Journal Form', params: { formtype:'Edit', id:id, view:view }});
            window.open(routeData.href, '_blank');
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }
                if (result.isConfirmed == true) {
                    generalJournalServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.GeneralJournalStatusForm.statusClick(data);
        },
        pdfClick(id) {
            const routeData = this.$router.resolve({name: 'General Journal Html Template', params: { id:id }});
            window.open(routeData.href, '_blank');
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.Status = null;
                    vue.gridChange();
                });
                $("#btnNew").on('click', function() {
                    vue.Status = "New";
                    vue.gridChange();
                });
                $("#btnApproval1").on('click', function() {
                    vue.Status = "Approval 1";
                    vue.gridChange();
                });
                $("#btnApproved").on('click', function() {
                    vue.Status = "Approved";
                    vue.gridChange();
                });
                $("#btnRevisi").on('click', function() {
                    vue.Status = "Revisi";
                    vue.gridChange();
                });
            })
        },
        addPurchasingPaymentClick(){
            this.$refs.PurchasingPaymentCreateForm.addClick();
        }
    }
}
</script>

<style scoped>
</style>